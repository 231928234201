#app {
  height: 100vh;
  width: 100%;
  margin: 0;
  border: none;
  padding: 0;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0 !important;
  overflow: hidden !important;
}
